// Navbar.js
import React from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  Box,
  Flex,
  Image,
  List,
  ListItem,
  Text,
  Button,
} from "@chakra-ui/react";
import { FaHome, FaUsers, FaUser, FaSignOutAlt } from "react-icons/fa";
import logoImage from "../assets/logo-horizontal.svg"; // Adjust the path as necessary

function Navbar() {
  const location = useLocation();
  const navigate = useNavigate();

  const isActive = (path) => location.pathname === path;

  const handleLogout = () => {
    // Add your logout logic here
    navigate("/");
  };

  return (
    <Flex
      as="nav"
      justify="space-between"
      align="center"
      p="20px 50px"
      bg="#B2C868"
      color="white"
      height="100px"
    >
      <Image
        src={logoImage}
        alt="Company Logo"
        boxSize="200px"
        objectFit="contain"
      />
      <List display="flex" alignItems="center">
        <ListItem m="0 15px">
          <Link to="/dashboard">
            <Button
              leftIcon={<FaHome />}
              color="black"
              // variant="dashboardButton"
              bg={isActive("/dashboard") ? "secondary1" : "primary2"}
              boxShadow={isActive("/dashboard") ? "0 0 3px white" : "none"}
            >
              Dashboard
            </Button>
          </Link>
        </ListItem>
        <ListItem m="0 15px">
          <Link to="/candidates">
            <Button
              leftIcon={<FaUsers />}
              color="black"
              bg={isActive("/candidates") ? "secondary1" : "primary2"}
              boxShadow={isActive("/candidates") ? "0 0 3px white" : "none"}
            >
              Candidates
            </Button>
          </Link>
        </ListItem>
        <ListItem m="0 15px">
          <Link to="/profile">
            <Button
              leftIcon={<FaUser />}
              color="black"
              bg={isActive("/profile") ? "secondary1" : "primary2"}
              boxShadow={isActive("/profile") ? "0 0 3px white" : "primary2"}
            >
              My Profile
            </Button>
          </Link>
        </ListItem>
        <ListItem m="0 15px">
          <Button
            variant="outline-rounded-bgWhite"
            leftIcon={<FaSignOutAlt />}
            onClick={handleLogout}
          >
            Logout
          </Button>
        </ListItem>
      </List>
    </Flex>
  );
}

export default Navbar;
