// ChakraWrapper.js
import React from "react";
import { ChakraProvider } from "@chakra-ui/react";
import { extendTheme } from "@chakra-ui/react";

const customTheme = extendTheme({
  colors: {
    primary1: "#B2C868",
    primary2: "#D5E376",
    secondary1: "#FF4800",
    secondary2: "#BDCFD5",
    accent1: "#FF4800",
    textActive: "white",
    textInactive: "black",
    gradients: {
      primary: "linear(to-r, #58b6c0, #04aec1)",
      secondary: "linear(to-r, #04aec1, #58b6c0)",
    },

    custom: {
      50: "#FFFFFF",
      100: "#f2f2f2",
      200: "#dbdbdb",
      300: "#eaeaea",
      400: "#D7DDE8",
      500: "#eef2f3",
    },
  },

  // --------------------
  fonts: {
    heading: "Arial, sans-serif",
    body: "Arial, sans-serif",
  },

  // --------------------

  components: {
    Button: {
      variants: {
        active: {
          borderColor: "primary1",
          borderWidth: "2px",
          color: "primary1",
        },
        "outline-rounded": {
          border: "1px solid black",
          color: "black",
          borderRadius: "5px",
          _hover: {
            bg: "secondary2",
          },
        },
        "outline-rounded-bgWhite": {
          border: "1px solid black",
          color: "black",
          borderRadius: "5px",
          _hover: {
            bg: "white",
          },
        },

        dashboardButton: {
          bg: "primary1",
          _hover: {
            bg: "primary2",
          },
        },
      },
    },
  },
});
const ChakraWrapper = ({ children }) => {
  return <ChakraProvider theme={customTheme}>{children}</ChakraProvider>;
};

export default ChakraWrapper;
