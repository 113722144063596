import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  useToast,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";

const Register = () => {
  const [formData, setFormData] = useState({
    fname: "",
    sname: "",
    email: "",
    employeeno: "",
    DDroleID: "3",
  });
  const [UUID, setUUID] = useState("");
  const [HuserID, setHuserID] = useState("");
  const toast = useToast();
  const navigate = useNavigate();

  useEffect(() => {
    const storedUUID = localStorage.getItem("UUID");
    const storedHuserID = localStorage.getItem("HuserID");

    if (storedUUID && storedHuserID) {
      setUUID(storedUUID);
      setHuserID(storedHuserID);
    } else {
      // Handle the case when uuid not available
      toast({
        title: "Error",
        description: "User authentication failed. Please log in again.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      navigate("/login"); // Redirect to login page
    }
  }, [navigate, toast]);

  const handleBack = () => {
    navigate("/candidates");
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!UUID) {
      toast({
        title: "Error",
        description: "Authentication token is missing. Please log in again.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    try {
      const formDataS = new FormData();
      Object.keys(formData).forEach((key) => {
        formDataS.append(key, formData[key]);
      });

      const response = await fetch(
        `https://qaapirgeneng.digitalfields.co.za/ws1.cfc?method=adduser&UUID=${UUID}&HuserID=${HuserID}`,
        // HuserID=${HuserID}
        {
          method: "POST",
          body: formDataS,
        }
      );

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || `HTTP error ${response.status}`);
      }

      toast({
        title: "Success",
        description: "Candidate added successfully.",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
      navigate("/candidates");
    } catch (error) {
      toast({
        title: "Error",
        description: `There was an error adding the candidate: ${error.message}`,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  return (
    <Box p={5}>
      <Button onClick={handleBack} mb={4}>
        Back to Candidates
      </Button>
      <Box display="flex" justifyContent="center" alignItems="center">
        <Box
          width="100%"
          maxWidth="500px"
          p={5}
          borderWidth="1px"
          borderRadius="lg"
          boxShadow="lg"
        >
          <form onSubmit={handleSubmit}>
            <FormControl id="fname" mb={3} isRequired>
              <FormLabel>First Name (fname)</FormLabel>
              <Input
                type="text"
                name="fname"
                value={formData.fname}
                onChange={handleChange}
              />
            </FormControl>
            <FormControl id="sname" mb={3} isRequired>
              <FormLabel>Last Name (sname)</FormLabel>
              <Input
                type="text"
                name="sname"
                value={formData.sname}
                onChange={handleChange}
              />
            </FormControl>
            <FormControl id="email" mb={3} isRequired>
              <FormLabel>Email (email)</FormLabel>
              <Input
                type="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
              />
            </FormControl>
            <FormControl id="employeeno" mb={3}>
              <FormLabel>Employee Number (employeeno)</FormLabel>
              <Input
                type="text"
                name="employeeno"
                value={formData.employeeno}
                onChange={handleChange}
              />
            </FormControl>
            <Input type="hidden" name="DDroleID" value={formData.DDroleID} />
            <Input type="hidden" name="HuserID" value={formData.HuserID} />
            <Button colorScheme="blue" type="submit" width="100%">
              Submit
            </Button>
          </form>
        </Box>
      </Box>
    </Box>
  );
};

export default Register;
