import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../contexts/AuthContext";
import {
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Image,
  VStack,
  Heading,
  useToast,
} from "@chakra-ui/react";
import logoImage from "../../assets/betapersonaB_Black.png";

function Signin() {
  const { login } = useContext(AuthContext);
  const [emailaddress, setEmailAddress] = useState("desh2@basrus.co.za");
  const [userpass, setUserPass] = useState("1234");
  const [error, setError] = useState("");
  const navigate = useNavigate();
  const toast = useToast();

  const handleLogin = async () => {
    try {
      // username should be changed to loginname?
      const credentials = { username: emailaddress, password: userpass };
      await login(credentials);
      navigate("/dashboard");
    } catch (error) {
      setError(
        `Signin failed: ${
          error.response ? error.response.data.message : error.message
        }`
      );
      toast({
        title: "Signin Failed",
        description: error.response
          ? error.response.data.message
          : error.message,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      height="100vh"
      bg="#f0f0f0"
    >
      <Image src={logoImage} alt="Company Logo" boxSize="150px" mb="20px" />
      <Heading mb="8">Signin</Heading>
      <VStack spacing={4} align="stretch" w="300px">
        {error && <FormErrorMessage>{error}</FormErrorMessage>}
        <FormControl id="email" isInvalid={error}>
          <FormLabel>Email address</FormLabel>
          <Input
            type="email"
            placeholder="Email address"
            value={emailaddress}
            onChange={(e) => setEmailAddress(e.target.value)}
          />
        </FormControl>
        <FormControl id="password" isInvalid={error}>
          <FormLabel>Password</FormLabel>
          <Input
            type="password"
            placeholder="Password"
            value={userpass}
            onChange={(e) => setUserPass(e.target.value)}
          />
        </FormControl>
        <Button variant="dashboardButton" onClick={handleLogin}>
          Signin
        </Button>
      </VStack>
    </Box>
  );
}

export default Signin;
