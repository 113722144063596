const API_BASE_URL = "https://qaapirgeneng.digitalfields.co.za/ws1.cfc?";

const fetchData = async (method, options = {}) => {
  const response = await fetch(`${API_BASE_URL}?method=${method}`, {
    mode: "no-cors",
    ...options,
    headers: {
      "Content-Type": "application/json",
      ...options.headers,
    },
  });

  if (!response.ok) {
    throw new Error("Network response was not ok");
  }

  return response.json();
};

export const apiService = {
  example: (method, params) =>
    fetchData(method, {
      method: "POST",
      body: JSON.stringify(params),
    }),

  getData: (method) => {
    const token = localStorage.getItem("token");
    fetchData(method, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  },

  login: async (options = {}) => {
    const method = "login";
    const { username, password } = options;

    if (!username || !password) {
      throw new Error("Invalid parameters");
    }

    // Build the query parameters string
    const queryParams = new URLSearchParams({
      method,
      ...(username && { username: username }),
      ...(password && { password }),
    });
    console.log(`${API_BASE_URL}${queryParams}`);

    // console.log(queryParams.toString())
    const response = await fetch(`${API_BASE_URL}${queryParams}`);

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    return response.json();
  },
};
