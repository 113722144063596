import React, { createContext, useState } from "react";
import { apiService } from "../services/apiService";

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [UUID, setUUID] = useState(localStorage.getItem("UUID") || null);
  const [token, setToken] = useState(localStorage.getItem("token") || null);
  const [HuserID, setHuserID] = useState(
    localStorage.getItem("HuserID") || null
  );

  const login = async (credentials) => {
    try {
      const data = await apiService.login(credentials);

      setToken(data[0].Token);

      console.log("login-data", data);
      localStorage.setItem("token", data[0].Token);
      console.log(data[0].Token);

      setUUID(data[0].UUID);
      localStorage.setItem("UUID", data[0].UUID);

      setHuserID(data[0].HuserID);
      // HuserID(data[0].HuserID);
      localStorage.setItem("HuserID", data[0].HuserID);
      // setHuserID(data.HuserID);
    } catch (error) {
      console.error("Login failed:", error);
      throw error;
    }
  };

  const logout = () => {
    setUUID(null);
    setHuserID(null);
    localStorage.removeItem("UUID");
    localStorage.removeItem("HuserID");
  };

  //   useEffect(() => {
  //     if (UUID) {
  //       // UUID refresh logic here
  //     }
  //   }, [UUID]);

  return (
    <AuthContext.Provider value={{ UUID, HuserID, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};
