import React, { useEffect, useState, useRef, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Button,
  Spinner,
  useToast,
  Box,
  Heading,
  Input,
  InputGroup,
  InputLeftElement,
  Flex,
  Spacer,
} from "@chakra-ui/react";
import { AddIcon, SearchIcon, CloseIcon } from "@chakra-ui/icons";

const Candidates = () => {
  const [candidates, setCandidates] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");
  const toast = useToast();
  const navigate = useNavigate();
  const tableRef = useRef(null);

  const UUID = localStorage.getItem("UUID");
  const HuserID = localStorage.getItem("HuserID");

  const API_BASE_URL = "https://qaapirgeneng.digitalfields.co.za/ws1.cfc";

  const fetchCandidates = useCallback(
    async (searchKey = "") => {
      try {
        setLoading(true);
        let url = `${API_BASE_URL}?method=listcandidates&huserID=${HuserID}&uuid=${UUID}`;

        if (searchKey.length >= 3) {
          url += `&searchkey=${encodeURIComponent(searchKey)}`;
        }

        const response = await fetch(url, {
          method: "POST",
        });

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();
        setCandidates(data);
      } catch (error) {
        console.error("Error fetching candidates:", error);
        toast({
          title: "Error fetching candidates",
          description:
            "There was an error fetching the candidates. Please try again.",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      } finally {
        setLoading(false);
      }
    },
    [HuserID, UUID, toast]
  );

  useEffect(() => {
    fetchCandidates();
  }, [fetchCandidates]);

  const handleSearch = () => {
    if (searchTerm.length >= 3 || searchTerm.length === 0) {
      fetchCandidates(searchTerm);
    } else {
      toast({
        title: "Search term too short",
        description: "Please enter at least 3 characters to search",
        status: "warning",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      handleSearch();
    }
  };

  const clearSearch = () => {
    setSearchTerm("");
    fetchCandidates("");
  };

  const handleViewEdit = (candidate) => {
    navigate(`/candidate/${candidate.CandidateID}`, {
      state: { scrollPosition: tableRef.current?.scrollTop },
    });
  };

  useEffect(() => {
    if (tableRef.current && window.history.state?.scrollPosition) {
      tableRef.current.scrollTop = window.history.state.scrollPosition;
    }
  }, []);

  if (loading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <Spinner size="xl" />
      </Box>
    );
  }

  return (
    <Box p={5}>
      <Heading mb={5}>Candidate List</Heading>
      <Flex align="center" mb={5}>
        <Button
          variant="outline-rounded"
          leftIcon={<AddIcon />}
          onClick={() => navigate("/register")}
        >
          Add Candidate
        </Button>
        <Spacer />
        <Flex gap={2} alignItems="center">
          <InputGroup width="300px">
            {searchTerm.length === 0 && (
              <InputLeftElement pointerEvents="none">
                <SearchIcon color="gray.400" />
              </InputLeftElement>
            )}
            <Input
              placeholder="Search candidates (min. 3 chars)"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              onKeyPress={handleKeyPress}
            />
          </InputGroup>
          <Button
            onClick={handleSearch}
            display="flex"
            alignItems="center"
            justifyContent="center"
            w="40px"
            p={0}
          >
            <SearchIcon />
          </Button>
          <Button
            onClick={clearSearch}
            display="flex"
            alignItems="center"
            justifyContent="center"
            w="40px"
            p={0}
          >
            <CloseIcon />
          </Button>
        </Flex>
      </Flex>
      <Box ref={tableRef} overflowY="auto" maxHeight="70vh">
        <Table variant="striped">
          <Thead>
            <Tr>
              <Th>ID</Th>
              <Th>First Name</Th>
              <Th>Last Name</Th>
              <Th>Email</Th>
              <Th>Active</Th>
              <Th>Actions</Th>
            </Tr>
          </Thead>
          <Tbody>
            {candidates.map((candidate) => (
              <Tr key={candidate.CandidateID}>
                <Td>{candidate.CandidateID}</Td>
                <Td>{candidate.fname}</Td>
                <Td>{candidate.sname}</Td>
                <Td>{candidate.email}</Td>
                <Td>{candidate.activeYN ? "Yes" : "No"}</Td>
                <Td>
                  <Button
                    variant="outline-rounded"
                    onClick={() => handleViewEdit(candidate)}
                  >
                    View/Edit
                  </Button>
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </Box>
    </Box>
  );
};

export default Candidates;
