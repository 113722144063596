import React, { useState, useCallback, useRef } from "react";
import { Box, Heading, Button, useToast, Text, VStack } from "@chakra-ui/react";
import { FaUpload } from "react-icons/fa";

const API_BASE_URL = "https://qaapirgeneng.digitalfields.co.za/ws1.cfc";

function Home() {
  const toast = useToast();
  const [file, setFile] = useState(null);
  const [isUploading, setIsUploading] = useState(false);
  const fileInputRef = useRef(null);

  const HuserID = localStorage.getItem("HuserID");
  const UUID = localStorage.getItem("UUID");

  const handleFileChange = useCallback((event) => {
    const selectedFile = event.target.files[0];
    setFile(selectedFile);
  }, []);

  const handleUpload = useCallback(async () => {
    if (!file) {
      toast({
        title: "No file selected",
        description: "Please select an Excel file to upload.",
        status: "warning",
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    setIsUploading(true);
    const formData = new FormData();
    formData.append("uploadfile", file);

    try {
      const response = await fetch(
        `${API_BASE_URL}?method=uploadScoresSheet&huserID=${HuserID}&hclientID=1&UUID=${UUID}`,
        {
          method: "POST",
          body: formData,
        }
      );

      if (!response.ok) {
        const errorData = await response.json().catch(() => ({}));
        throw new Error(errorData.message || "Network response was not ok");
      }

      const data = await response.text();
      console.log(data);

      toast({
        title: "Upload successful",
        description: "The file has been uploaded successfully.",
        status: "success",
        duration: 2000,
        isClosable: true,
      });

      // Reset file input and state after successful upload
      setFile(null);
      if (fileInputRef.current) {
        fileInputRef.current.value = "";
      }
    } catch (error) {
      console.error("There was a problem with the fetch operation:", error);
      toast({
        title: "Upload failed",
        description: error.message || "There was an error uploading the file.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setIsUploading(false);
    }
  }, [file, HuserID, toast]);

  return (
    <Box textAlign="center" mt="50px">
      <VStack spacing={6}>
        <Heading>Dashboard</Heading>
        <input
          type="file"
          accept=".xlsx, .xls"
          style={{ display: "none" }}
          id="upload-input"
          onChange={handleFileChange}
          ref={fileInputRef}
        />
        <label htmlFor="upload-input">
          <Button
            as="span"
            variant="outline-rounded"
            leftIcon={<FaUpload />}
            width="200px"
            cursor="pointer"
          >
            Upload Spreadsheet
          </Button>
        </label>
        {file && <Text>{file.name}</Text>}
        <Button
          onClick={handleUpload}
          variant="dashboardButton"
          width="200px"
          isLoading={isUploading}
          loadingText="Uploading..."
        >
          Submit
        </Button>
      </VStack>
    </Box>
  );
}

export default Home;
